@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400&display=swap);
* {
  box-sizing: border-box;
}

body {
  font-size: 16px;
  /* margin: auto;
  width: 640px; */
  background: #e2e8f0;
  font-family: Roboto, Noto Sans KR, sans-serif, -apple-system,
    AppleSDGothicNeo-Regular, BlinkMacSystemFont, Helvetica, Arial;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

.main {
  margin: auto;
  max-width: 768px;
}

.card-header {
  line-height: 36px;
  font-size: 20px;
}

h5 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}
.title {
  height: 27px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.board {
  max-width: 768px;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.main-footer {
  position: absolute;
  margin-top: 950px;
  left: 0;
  background-color: #252525;
  width: 100%;
  height: 250px;
  color: #a0a0a0;
}

.contact {
  width: -webkit-max-content;
  width: max-content;
  margin-top: 80px;
  margin-bottom: 40px;
}
.email {
  text-decoration: none;
  color: #a0a0a0;
  font-size: 18px;
  font-weight: 700;
  margin-left: 10px;
}
.email:hover {
  color: #fff;
}

.nickname {
  margin: 3px 2px 3px 10px;
  line-height: 20px;
  text-decoration: none;
  color: #000;
  color: initial;
  font-weight: 600;
}

.nickname:hover {
  text-decoration: underline;
  color: #000;
  color: initial;
}

.badge {
  /* height: 16px; */
  width: 50px;
  border-radius: 3px;
  margin: 2px 0;
  padding: 0;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  line-height: 16px;
  color: white;
  font-family: Roboto, -apple-system, AppleSDGothicNeo-Regular,
    BlinkMacSystemFont;
}

.small {
  width: 40px;
  font-size: 11px;
  margin-left: 3px;
}

.timestamp {
  margin: 0 0 0 12px;
  line-height: 36px;
  /* line-height: 28px; */
  font-size: 12px;
  font-weight: 700;
  /* font-weight: 500; */
  color: rgb(120, 124, 126);
  text-decoration: none;
}
.timestamp:hover {
  color: rgb(120, 124, 126);
}

.tooltip-inner {
  max-width: 100%;
  font-size: 14px;
}

.mbti {
  width: 80px;
  border-radius: 3px;
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
}

/* iframe,
video {
  max-width: 28vw;
  max-height: 15.75vw;
} */

.card-body iframe,
video {
  display: block;
  max-width: 28vw;
  max-height: 15.75vw;
}

.card-body img {
  display: block;
  max-width: 100%;
  height: auto;
}

.masking {
  -webkit-mask-image: linear-gradient(180deg, #000 75%, transparent);
}

.read {
  width: 100%;
  display: flex;
  position: absolute;
  z-index: 99;
  bottom: 64px;
  right: 0px;
}

.read-more {
  width: 72px;
  height: 32px;
  font-size: 16px;
  text-align: center;
  line-height: 32px;
  margin: auto;
  text-decoration: none;
  color: #ffffff;
  /* background: #99c1f5; */
  background: #478ffa;
  border-radius: 8px;
}

.read-more:hover {
  color: #ffffff;
}

.editor {
  width: 100%;
  max-width: 768px;
  /* height: 800px; */
  margin: auto;
  display: flex;
  flex-direction: column;
  margin: auto;
  background: white;
  border-radius: 16px;
}

/* .dropdown-toggle::after {
  display: none;
} */
.dropdown-toggle:not(#dropdown-split-basic)::after {
  display: none;
}

.more:hover {
  background-color: rgba(190, 190, 190, 0.3);
}

.dropdown-item:hover,
.dropdown-item:focus,
.dropdown-item:active {
  color: #000;
  background-color: rgb(208, 208, 208, 0.3);
}

.user {
  width: 100%;
  max-width: 768px;
  min-height: 600px;
  margin: auto;
  /* display: flex;
  flex-direction: column;
  align-items: flex-end; */
  background: white;
  border-radius: 10px;
}

.account {
  margin: 20px 40px;
  display: flex;
  justify-content: center;
}

.delete-account {
  font-size: 15px;
  /* color: #fa7476; */
  color: #1876f2;
  border: none;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 16px;
  background: transparent;
  display: flex;
}

.nav-link {
  color: #65676b;
  font-size: 20px;
  font-weight: 600;
}

.nav-link:hover,
.nav-link:focus {
  color: #1876f2;
}

.active {
  color: #1876f2;
  border-bottom: 5px solid #1876f2;
}

.list-group-item {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  padding: 5px 20px;
  height: 50px;
}

.list-group-item a {
  text-decoration: none;
  color: inherit;
}

.posts p {
  width: 480px;
  margin: 0;
  height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.likes p {
  width: 720px;
  margin: 0;
  height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

input {
  outline: none;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 400;
}

.upload {
  width: 100%;
  height: 100%;
  max-width: 768px;
  max-height: 786px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1500;
  position: fixed;
  background: rgba(255, 255, 255, 0.75);
}

.card-footer > p {
  margin: 0;
}

.comment-content > p {
  margin-bottom: 10px;
}

.card-footer {
  font-size: 16px;
  margin-top: 16px;
}

.footer {
  width: 90px;
  display: flex;
  text-decoration: none;
  cursor: pointer;
  height: 32px;
  margin: 3px 0;
  border-radius: 3px;
}

.comment-footer {
  width: 60px;
  display: flex;
  text-decoration: none;
  cursor: pointer;
  height: 24px;
  margin: 3px 0;
  border-radius: 3px;
}
.comment-footer span {
  max-width: 48px;
  line-height: 14px;
  font-size: 14px;
  text-align: center;
}

/* .footer:hover {
  background-color: rgba(190, 190, 190, 0.3);
} */

#comment:focus,
#comment:active {
  background-color: inherit;
  outline: none;
  box-shadow: none;
  color: inherit;
}
.reply-editor {
  margin-right: 0px;
  max-width: 685px;
}

.reply {
  margin-right: 0;
  max-width: 96%;
}

/* #search {
  background-image: url(../public/search.svg);
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: 10px 9px;
  padding-left: 40px;
} */
.head-container {
  height: 46px;
  margin: 0;
}

.first {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  /* transition: all 0.3s; */
}
.second {
  -webkit-transform: translateY(-46px);
          transform: translateY(-46px);
  /* transition: all 0.3s; */
}
.third {
  -webkit-transform: translateY(-92px);
          transform: translateY(-92px);
  /* transition: all 0.3s; */
}
.fourth {
  -webkit-transform: translateY(-138px);
          transform: translateY(-138px);
  /* transition: all 0.3s; */
}
.fifth {
  -webkit-transform: translateY(-184px);
          transform: translateY(-184px);
  /* transition: all 0.3s; */
}

.best-head {
  width: 100%;
  white-space: nowrap;
  color: inherit;
  line-height: 46px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
}
.best-head:hover {
  color: inherit;
}

.best-badge {
  margin: 14px 8px 0 0;
  font-size: 12px;
  min-width: 5%;
  height: 22px;
  line-height: 20px;
  color: white;
  text-align: center;
  border-radius: 4px;
  background: #1876f2;
}
.best-number {
  color: #ff6c85;
  font-weight: 700;
  margin: 0 10px 0 0;
  line-height: 46px;
}

.best p {
  line-height: 24px;
}
.comment-badge {
  line-height: 16px;
  font-size: 14px;
  font-weight: 500;
  margin: 0 1px 0 6px;
}
#dropdown-split-basic {
  line-height: 27px;
}

@media (hover: hover) {
  .footer:hover {
    background-color: rgba(190, 190, 190, 0.3);
    /* outline: 1px solid rgba(0, 0, 0, 0.3); */
  }

  .tab-items:hover {
    text-decoration: underline;
  }
  /* 
  #comment:hover {
    background-color: rgba(33, 37, 41, 0.9);
    color: inherit;
  } */
}

@media screen and (max-width: 1920px) {
  .card-body iframe,
  video {
    max-width: 36vw;
    max-height: 20.25vw;
  }
}

@media screen and (max-width: 1680px) {
  .card-body iframe,
  video {
    max-width: 41vw;
    max-height: 23vw;
  }
}
@media screen and (max-width: 1440px) {
  .card-body iframe,
  video {
    max-width: 48vw;
    max-height: 27vw;
  }
}

@media screen and (max-width: 1200px) {
  .card-body iframe,
  video {
    max-width: 57vw;
    max-height: 32vw;
  }
}

@media screen and (max-width: 1024px) {
  h5 {
    font-size: 20px;
  }

  .main-footer {
    margin-top: 870px;
  }

  .card-body iframe,
  video {
    max-width: 68vw;
    max-height: 38.25vw;
  }
  .comment {
    margin-left: 0px;
    margin-right: 0px;
    max-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  body {
    font-size: 14px;
  }

  .card-body iframe,
  video {
    max-width: 88vw;
    max-height: 49.5vw;
  }

  .list-group-item {
    font-size: 15px;
  }
  .upload {
    width: 100%;
    height: 100%;
    max-width: 768px;
    max-height: 360px;
    top: 0;
    padding-bottom: 120px;
  }
  .reply-editor {
    max-width: 89vw;
  }
}

@media screen and (max-width: 576px) {
  h5 {
    font-size: 16px;
  }

  .nickname {
    font-size: 14px;
  }

  .main-footer {
    margin-top: 750px;
  }

  .card-footer {
    font-size: 14px;
  }

  .card-footer svg {
    width: 16px;
    height: 16px;
  }

  .nav-link {
    font-size: 18px;
  }

  .read-more {
    width: 64px;
    height: 28px;
    font-size: 14px;
    line-height: 28px;
  }

  .best-badge {
    min-width: 10%;
  }

  .likes p {
    width: 320px;
  }

  .posts p {
    width: 200px;
  }
  .reply {
    margin-left: 20px;
    max-width: 94vw;
  }
  .reply-editor {
    max-width: 82vw;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

